export default {
  props: {
    value: {type: Boolean, default: false},
    item: {type: Object, default: () => null},
    isLoading: {type: Boolean, default: false},
  },

  data() {
    return {
      form: {},
      //isLoading: {btnSave: false}
    }
  },

  created() {

    this.$store.commit("alert/hideAlert");

    if (this.item) {
      const a = this.$R.pick(Object.keys(this.defaultForm), this.item);
      this.form = this.$R.mergeDeepRight(this.defaultForm, a);
    }
    else
      this.form = { ...this.defaultForm };
  },

  methods: {
    submit() {
      this.$emit('submit', this.form);
    },

    close(needRefresh = false) {
      this.$store.commit('alert/hideAlert');
      this.form = { ...this.defaultForm };
      this.$emit('close', needRefresh);
    },

    // Set only "id" of objects in the form before sending to the API.
    shortcutFormObjects(arrayProp = [], targetProp = "id") {
      arrayProp.forEach((prop)  => {
        if (this.form[prop]) {
          if (Array.isArray(this.form[prop])) {
            const tmpArr = [];
            this.form[prop].forEach(el => {
              if (el[targetProp])
                tmpArr.push(el[targetProp]);
              else
                tmpArr.push(el);
            })
            this.form[prop] = tmpArr;
          }
          else {
            if (this.form[prop][targetProp])
              this.form[prop] = this.form[prop][targetProp];
          }
        }
      })
    },
  },

  watch: {
    value: function(value) {
      this.$store.commit('alert/hideAlert');

      if (value && this.item)
        this.form = this.$R.pick(Object.keys(this.defaultForm), this.item);
      else if (value && !this.item)
        this.form = {...this.defaultForm};
    }
  }
}
