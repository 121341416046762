export default {

  data() {

    return {
      valid: false,
      requiredRules: [
        v => (v != null && v !== "") || this.$t('common.errors.IS_REQUIRED')
      ],
      maxLength40: [
        (v) => {
          if (v) {
            for (let i = 0; i < v.length; i++)
              if (v[i].length > 40)
                return this.$tc('common.errors.MAX_LENGTH', {length: 40});
          }

          return true;
        }
      ]
    }

  }
}
