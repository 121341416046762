<template>
  <v-dialog :value="value" @input="close" max-width="600px">
    <v-card>

      <!-- Title -->
      <v-card-title>
        <h3 v-if="item">{{ $t('links.mainDialog.edit.title') }}</h3>
        <h3 v-else>{{ $t('links.mainDialog.add.title') }}</h3>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>

            <!-- Label -->
            <v-col cols="12" class="mt-12">
              <v-text-field
                  outlined
                  :rules="requiredRules"
                  :label="$t('links.labels.label')"
                  maxlength="100"
                  hide-details
                  v-model="form.label" />
            </v-col>

            <!-- Type -->
            <v-col cols="12" class="mt-3">
              <v-select
                outlined
                :rules="requiredRules"
                :items="$t('constants.linkTypes.list')"
                :label="$t('links.labels.type')"
                :value="form.type"
                @input="onInputType"
                hide-details
              />
            </v-col>

            <!-- Account -->
            <v-col cols="12" class="mt-3">
              <SelectAccount
                  :value="form.account"
                  @input="onInputAccount"
              />
            </v-col>

            <!-- Product -->
            <v-col cols="12" class="mt-3">
              <SelectProduct
                :account-id="selectedAccount"
                :value="form.product"
                @input="onInputProduct"
              />
            </v-col>

            <!-- Study -->
            <v-col cols="12" class="mt-3">
              <SelectStudy
                  :account-id="selectedAccount"
                  :product-id="selectedProduct"
                  :value="form.study"
                  @input="onInputStudy"
              />
            </v-col>

            <!-- Static link
            <v-col cols="12" class="mt-3">
              <v-text-field
                readonly
                outlined
                :rules="requiredRules"
                :label="$t('links.labels.static_link')"
                type="url"
                v-model="form.static_link" />
            </v-col-->

            <!-- Dynamic link -->
            <v-col cols="12" class="mt-3">
              <v-text-field
                outlined
                :rules="requiredRules"
                :label="$t('links.labels.dynamic_link')"
                type="url"
                v-model="form.dynamic_link" />
            </v-col>

            <!-- Enabled -->
            <v-col cols="12">
              <v-switch
                outlined
                :label="$t('links.labels.enabled')"
                v-model="form.enabled" />
            </v-col>

            <v-col cols="12">
              <AlertBar />
            </v-col>

          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <ButtonBarFormDialog
            class="mx-3"
            :is-loading-btn-save="isLoadingBtn.btnSave"
            @submit="customSubmit"
            @cancel="close(false)" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialog"
import formRules   from "@/mixins/formRules";
import { uuid }    from 'vue-uuid';

export default {
  name: "LinkMainDialog",

  mixins: [dialogMixin, formRules],

  components: {
    SelectProduct: () => import("@/components/Common/Inputs/SelectProduct"),
    SelectStudy: () => import("@/components/Common/Inputs/SelectStudy"),
    SelectAccount: () => import("@/components/Common/Inputs/SelectAccount"),
    AlertBar: () => import("@/components/Common/Bars/AlertBar"),
    ButtonBarFormDialog: () => import("@/components/Common/Buttons/ButtonBarFormDialog"),
  },

  props: {
    product: {type: Object, default: () => null},
    study: {type: Object, default: () => null}
  },

  data() {
    return {
      isLoadingBtn: { btnSave: false },
      defaultForm: {
        label: "Link ",
        ref: uuid.v4(),
        type: "OTHER",
        static_link: "",
        dynamic_link: "",
        enabled: true,
        account: null,
        study: null,
        product: null
      },
    }
  },

  created() {

    if (this.study) {
      this.form.product = this.product;
      this.form.account = this.study.account;
      this.form.study = this.study;
    }
  },

  methods: {
    customSubmit() {
      if (this.$refs.form.validate()) {
        this.isLoadingBtn.btnSave = true;

        this.shortcutFormObjects(["account", "study", "product"]);
        if (this.item)
          this.submitEdit();
        else
          this.submitAdd();
      }
    },

    submitAdd() {
      this.form.static_link = process.env.VUE_APP_LINK_URL+"/"+this.form.ref;

      this.$http.post(`/links`, this.form, {
            headers: {Authorization: "Bearer " + this.$session.get('jwt')}
          })
          .then(res => {
            this.$store.commit("toast/showSuccess", this.$t('links.mainDialog.add.success'));
            this.close(true);
          })
          .catch(err => {
            this.$store.commit("alert/showErrorAxios", err);
          })
          .finally(() => {
            this.isLoadingBtn.btnSave = false;
          });
    },


    submitEdit() {


      this.$http
          .put(`/links/${this.item.id}`, this.form, {
            headers: {Authorization: "Bearer " + this.$session.get('jwt')}
          })
          .then(res => {
            this.$store.commit("toast/showSuccess", this.$t('links.mainDialog.edit.success'));
            this.close(true);
          })
          .catch(err => {
            this.$store.commit("alert/showErrorAxios", err);
          })
          .finally(() => {
            this.isLoadingBtn.btnSave = false;
          });
    },

    onInputAccount(val) {
      this.form.account = val;
      this.refreshDynamicLink();
    },

    onInputProduct(val) {
      this.form.product = val;
    },

    onInputStudy(val) {
      this.form.study = val;
    },

    onInputType(val) {
      this.form.type = val;
      this.refreshDynamicLink();
    },

    refreshDynamicLink() {
      switch (this.form.type) {
        case "SHOWCASE": {

          this.form.dynamic_link = this.getCustomPath("showcases");
          if (this.form.label === this.defaultForm.label)
            this.form.label =  "Fiche Produit";
          break;
        }
        case "QRCODE": {
          this.form.dynamic_link = this.getCustomPath("qr-codes");
          if (this.form.label === this.defaultForm.label)
            this.form.label =  "QR Code";
          break;
        }
        case "WIDGET": {
          this.form.dynamic_link = this.getCustomPath("widgets");
          if (this.form.label === this.defaultForm.label)
            this.form.label =  "Widget";
          break;
        }
        case "OTHER":
          this.form.dynamic_link = "";
          break;
       }
    },

    getCustomPath(type) {
      let customPath = "";

      // Set custom myproductimpact path if account has enabled setting
      if (this.form.account && this.form.account.settings && this.form.account.settings.myproductimpact_path)
        customPath = `${this.form.account.settings.myproductimpact_path}/`;

      return `${process.env.VUE_APP_MYPRODUCTIMPACT_URL}/${customPath}${type}/${this.form.ref}`;
    }
  },

  computed: {
    selectedAccount() {
      if (this.form.account && typeof this.form.account === "object" && this.form.account['id'])
        return this.form.account.id;
      return this.form.account;
    },

    selectedProduct() {
      if (this.form.product && typeof this.form.product === "object" && this.form.product['id'])
        return this.form.product.id;
      return this.form.product;
    },
  }
}
</script>

<style scoped>

</style>
